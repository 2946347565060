.review-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;

  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.review-title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}

.review-icons-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media (min-width: 778px) {
  .review-wrapper {
    padding: 30px 20px;
    margin: 25px 6%;
  }

  .review-title {
    font-size: 25px;
  }

  .review-icons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
}
