.star-review-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 25px 6%;
  padding: 30px 20px;
}

.star-singular-review {
  display: flex;
  flex-direction: column;

  text-align: center;
  font-weight: 600;

  margin: 25px 0;
}

.star-singular-title {
  font-size: 25px;
  margin-bottom: 10px;
}

.star-singular-subtitle {
  font-size: 18px;
  margin-top: 10px;
}

@media (min-width: 778px) {
  .star-review-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .star-singular-review {
    margin: 0;
  }
}
