.review-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  padding: 20px;
  height: 200px;
  width: 200px;

  border-radius: 30px;

  background-color: #81D8D0;
  color: black;
}

.review-card-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;

  display: flex;
  flex-wrap: wrap;
}

.signer-review-card {
  font-size: 14px;
}

@media screen and (min-width: 778px) and (max-width: 1199px) {
  .review-card-wrapper {
    width: 100%;
    min-height: 250px;
    height: fit-content;
  }

  .review-card-title {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .review-card-wrapper {
    width: 100%;
    min-height: 250px;
    height: fit-content;
  }

  .review-card-title {
    font-size: 25px;
  }

  .signer-review-card {
    font-size: 18px;
  }
}
