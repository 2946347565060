.disclaimers-main-wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 60px;
}

.disclaimers-image-style {
  object-fit: cover;
  height: 10vh;
}

.disclaimers-title {
  padding: 20px;

  font-size: 30px;
  font-weight: 500;

  text-align: center;
  margin: 20px 20px 0px 20px;

  border-bottom: 2px black solid;
}

.disclaimers-content-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px 12%;
}

.disclaimer-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 30px;
}

.disclaimer-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;

  line-height: 23px;
}

@media (min-width: 778px) {
  .disclaimers-image-style {
    height: 15vh !important;
  }

  .disclaimers-title {
    margin: 20px 8% 0 8%;

    font-size: 30px;
    font-weight: 500;

    text-align: left;
  }
}
