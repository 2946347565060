.footer-wrapper {
  width: 100%;
}

.footer-wrapper > * {
  padding: 20px 40px;
}

.footer-title {
  font-size: 30px;
}

.info-data {
  font-size: 17px;
}

.footer-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
}

.footer-info-wrapper > * {
  padding-bottom: 30px;
}

.info-title {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 500;
}

.socials-powered-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.social-media-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex-wrap: wrap;
}

.anchor-social {
  color: white;
  text-decoration: none;
  font-size: 100px;
}

.anchor-social-img {
  width: 85px;
  color: white;
}

.anchor-social-etsy {
  color: white;
  text-decoration: none;
  font-size: 100px;
}

.anchor-social-img-etsy {
  width: 85px;
  color: white;
}

.info-locations-footer {
  max-height: 120px;
}

.lower-footer-powered {
  display: flex;
  justify-content: center;
  width: 200px;

  margin: 30px 0;
}

.lower-footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.corp-gifts-contact {
  width: 100%;
}

.copyright-wrapper {
  background-color: white;
  color: black;
  line-height: 25px;
  font-size: 15px;

  display: flex;
  justify-content: center;
  text-align: justify;
}

.copyright-wrapper > * {
  width: 90%;
  margin: 30px 0;
}

.footer-phone {
  color: white;
  border-bottom: 1px solid;
}

@media (min-width: 450px) and (max-width: 777px) {
  .socials-powered-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .social-media-wrapper {
    width: 80%;
  }

  .anchor-social {
    font-size: 80px;
  }

  .anchor-social-img {
    width: 60px;
  }

  .anchor-social-etsy {
    font-size: 80px;
  }

  .anchor-social-img-etsy {
    width: 60px;
  }

  .corp-gifts-contact {
    width: 100%;
  }
}

@media (min-width: 778px) {
  .footer-wrapper > * {
    padding: 40px 100px;
  }

  .footer-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
  }

  .info-title {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .social-media-wrapper {
    justify-content: center;
    align-items: flex-end;
  }

  .social-media-wrapper > * {
    margin: 0 15px;
  }

  .anchor-social {
    font-size: 50px;
  }

  .anchor-social-img {
    width: 50px;
  }

  .anchor-social-etsy {
    font-size: 70px;
  }

  .anchor-social-img-etsy {
    width: 70px;
  }

  .lower-footer-powered {
    width: 250px;
    margin-top: 0;
  }

  .lower-footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
  }

  .corp-gifts-contact {
    text-align: start;
    width: 50%;
  }

  .copyright-wrapper {
    line-height: 25px;
    font-size: 12px;
     padding: 40px 140px;
  }

  .copyright-wrapper > * {
    margin: 0;
   
  }
}

@media (min-width: 778px) and (max-width: 1140px) {
  .info-data {
    font-size: 12px;
  }

  .corp-gifts-contact {
    font-size: 15px;
  }
}
